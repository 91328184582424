<template>
  <div id="Manage">
    <!-- 基础设置 特权管理 分类-->
    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddUser"
                     icon="el-icon-circle-plus-outline">添加特权分类</el-button>
          <!-- <el-button type="danger"
                     size="small"
                     @click="clickDelUser"
                     icon="el-icon-delete">删除</el-button> -->
        </div>

        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    inline>
          <el-button type="primary"
                     @click="searchPrivilegeClassify(searchForm)">搜索</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <!-- <el-button type="primary"
                           size="mini"
                           icon="el-icon-edit"
                           @click="clickEditUser(scope.row)"></el-button> -->
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <span @click="clickDeleteData(scope.$index, scope.row)">删除</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { addPrivilege, getPrivilege } from 'api/basis.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增商品',
        editName: '编辑商品',
        isShowDialogVisible: false
      },
      // form表单 基础数据
      openForm: {
        name: '',
        icon: '',
        sort: '',
        status: false,
      },
      openFormLabel: [
        {
          model: 'name',
          label: '特权分类名称'
        },
        {
          model: 'icon',
          label: '图标',
          type: 'upload',
          maxSize: 500,
          imgHeight: '1080',
          imgWidth: '808',
          // required: true
        },
        {
          model: 'sort',
          label: '排序'
        },
        {
          model: 'status',
          label: '状态',
          type: 'switch'
        }
      ],
      // form表单 搜索数据
      searchForm: {
        keyword: ''
      },
      searchformLabel: [
        {
          model: 'keyword',
          label: '分类名称',
          labelOFF: true,
          options: []
        }
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '特权分类ID'
        },
        {
          prop: 'name',
          label: '分类名称'
        },
        {
          prop: 'icon',
          label: '图标'
        },
        {
          prop: 'sort',
          label: '排序'
        },
        {
          prop: 'state',
          label: '状态',
          type: 'stateNotouch'
        },
        {
          prop: 'update_time',
          label: '更新时间',
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示选择多行数据
        selection: true
      },

      // 旅游-全部-数据
      privilegeAllDataObj: {},
      searchData: '',//存放搜索的数据，分页时使用
      // 旅游-格式处理-列表数据
      // barndFormatDealListData: []
    }
  },
  mounted () {
    // this.getBasisTourList()
    this.getPrivilege()
  },
  watch: {
    privilegeAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.privilegeAllDataObj.list,
        {
          name: 'name',
          status: 'state',
          sort: 'sort',
          icon: 'icon',
        },
        0
      )
    }
  },

  methods: {
    //获得下列分类页的页数
    getPage () {
      // this.getPrivilege()
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getPrivilege(this.searchData)
      } else {
        this.getPrivilege()
      }
    },
    //   获取--分类列表数据
    getPrivilege (data) {
      if (data) {
        getPrivilege(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.privilegeAllDataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getPrivilege({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          console.log(this.tableConfig.page)
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.privilegeAllDataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }



    },

    //添加--编辑特权分类
    addPrivilege (met, data) {
      return new Promise(resolve => {
        addPrivilege(met, data).then(res => {
          console.log(res)
          const DATA = res.data
          resolve(DATA)
        })
      })
    },
    //操作数据添加或修改
    clickSubmitData (type, data) {

      if (type == 'add') {
        const DATA = {
          name: data.name,
          icon: data.icon,
          sort: data.sort,
          status: data.status
        }
        console.log(DATA)
        this.addPrivilege('POST', DATA)
        this.updataTableData()
      }
      else if (type == 'edit') {
        const DATA = {
          id: data.id,
          name: data.name,
          icon: data.icon,
          sort: data.sort,
          status: data.status
        }
        this.addPrivilege('PUT', DATA)
        this.updataTableData()
      }
      this.dialogFonfig.isShowDialogVisible = false
      this.openForm = this.$options.data().openForm;
      this.updataTableData()
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap)
    },
    //刷新表格数据
    updataTableData () {
      this.getPrivilege()
    },
    //添加数据
    clickAddUser () {
      console.log(this.openForm)
      this.dialogFonfig.isShowDialogVisible = true
      this.openForm = this.$options.data().openForm;
      this.updataTableData()
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      this.openForm = {
        name: '',
        icon: '',
        sort: '',
        status: true
      }
    },
    // 编辑数据
    clickEditData (idx, row) {

      // const state = row.state == 1
      this.openForm = {
        id: row.id,
        name: row.name,
        icon: row.icon,
        status: row.state,
        sort: row.sort,
      }
      console.log(row)
      // this.openForm.parentOptions = this.cascaderDataArr
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
      // console.log(this.openForm, this.cascaderDataArr, parentIdArr)
      this.updataTableData()
    },
    // 删除数据
    clickDeleteData (idx, row) {
      console.log(row.id)
      const Id = row.id
      this.addPrivilege('DELETE', Id)
      this.updataTableData()
    },
    //根据条件搜索
    searchPrivilegeClassify (searchForm) {
      const data = {
        search: searchForm.keyword
      }
      this.searchData = data
      this.getPrivilege(data)
    },
    //重置筛选条件
    resetFilter (searchForm) {

      searchForm.keyword = ''
      this.searchData = ''
      this.getPrivilege()
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
}
</style>
